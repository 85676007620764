import React from "react";
import SingleJobPage from "../../components/SingleJobPage";
import withLayout from "../../layout";
import {injectIntl} from "react-intl";
import SEO from "../../components/SEO";
/**
 * note that the key we defined in role is needed for building the SingleJobPage
 * component with the right translation strings and media
 * @returns { SingleJobPage }
 * @constructor
 */

const SingleJobPageWrapper = ({intl}) => {
    const IntlTextObj = {
        role: "uiux.mid.title",
        roleValue: "Ui/Ux MID",
        skills: {
            experience_title: "uiux.experience_title",
            experience: "uiux.mid.experience",
            employment_title: "uiux.employment_title",
            employment: "uiux.employment",
            placeofwork_title: "uiux.placeofwork_title",
            placeofwork: "uiux.placeofwork",
            title_left: "uiux.title_left",
            text_left: "uiux.text_left",
            title_right: "uiux.title_right",
            text_right: "uiux.text_right",
            text_center: "uiux.text_center",
        },
        hard_skills_title: "uiux.hard_skills_title",
        hard_skills: ["uiux.mid.hard_skill_1", "uiux.mid.hard_skill_2", "uiux.mid.hard_skill_3", "uiux.mid.hard_skill_4", "uiux.mid.hard_skill_5", "uiux.mid.hard_skill_6", "uiux.mid.hard_skill_7"],
        soft_skills_title: "uiux.soft_skills_title",
        soft_skills: ["uiux.mid.soft_skills_1", "uiux.mid.soft_skills_2", "uiux.mid.soft_skills_3", "uiux.mid.soft_skills_4", "uiux.mid.soft_skills_5"],
        adventure_title_thin: "uiux.adventure_title_thin",
        adventure_title: "uiux.adventure_title",
        benefits_title: "uiux.benefits_title",
        benefits: ["uiux.benefit_1", "uiux.benefit_2", "uiux.benefit_3", "uiux.benefit_4", "uiux.benefit_5"],
        offer_title: "uiux.offer_title",
        offer: ["uiux.offer_1", "uiux.offer_2", "uiux.offer_3", "uiux.offer_4", "uiux.offer_5"],
    };
    const keywords = intl.formatMessage({id: 'uiux.meta_keywords'}).split(',') || [];
    return (
        <>
            <SEO title={intl.formatMessage({id: 'uiux.meta_title'})}
                 description={intl.formatMessage({id: 'uiux.meta_description'})} keywords={keywords}
                 lang={intl.locale}/>
            <SingleJobPage IntlTextObj={IntlTextObj} intl={intl}/>
            {/* <SingleJobPage IntlTextObj /> */}
        </>
    );
};

const customProps = {
    localeKey: "uiux", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleJobPageWrapper));
